import { useEffect } from "react";
import { navigate } from "@reach/router"; // reach router ships with gatsby

import "./not-found.scss";

import Banner from "../Banner/Banner.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import Page from "../Page/Page.jsx";

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <Page title="Page not found" className="not-found">
      <Banner>
        <div>
          <div className="not-found__message">
            <h1>Oh no!</h1>
            <p>You look lost, let me help you back!</p>
          </div>
          <ContentfulLink
            to="/"
            type="internal"
            className="btn btn--transparent"
          >
            Take me back!
          </ContentfulLink>
        </div>
      </Banner>
    </Page>
  );
};

export default NotFoundPage;
